<template>
  <div>

    <v-btn-toggle dense mandatory v-model="fy" >
      <v-btn plain style="text-transform: unset;"
        v-for="fy in fys"
        :key="fy"
        :value="fy"
      >
        {{ fy }}
      </v-btn>
    </v-btn-toggle>

    <Table api="/api/heads" :params="apiParams1" class="mt-2" currencyFormat="currencyInr" :rowClassFn="rowClassFn1" />
    <Table api="/api/heads" :params="apiParams2" class="mt-2" currencyFormat="currencyInr" :rowClassFn="rowClassFn2" />
    <Table api="/api/heads" :params="apiParams3" class="mt-2" currencyFormat="currencyInr" :rowClassFn="rowClassFn3" />

  </div>
</template>

<script>
import axios from "axios";
import util from "@/util.js";
import Table from "../components/Table";
export default {
  components: {
    Table
  },
  props: {
    type: String
  },
  data() {
    return {
      fy: util.currFy,
      fys: [ util.currFy ],
      apiParams1: { type:this.type, fy:util.currFy, groupBy:'Account' },
      apiParams2: { type:this.type, fy:util.currFy, groupBy:'Tag'     },
      apiParams3: { type:this.type, fy:util.currFy, groupBy:'Head'    },
    }
  },
  watch: {
    type() {
      this.apiParams1.type = this.type;
      this.apiParams2.type = this.type;
      this.apiParams3.type = this.type;
    },
    fy() {
      this.apiParams1.fy = this.fy;
      this.apiParams2.fy = this.fy;
      this.apiParams3.fy = this.fy;
    }
  },
  mounted() {
    axios
      .get('/api/fys')
      .then((response) => {
        this.fys = response.data;
      })
      .catch((error) => {
        util.handleError(error);
      });
  },
  methods: {
    rowClassFn1 (row) {
      return row.Account == 'Total' ? 'font-weight-bold' : '';
    },
    rowClassFn2 (row) {
      return row.Tag == 'Total' ? 'font-weight-bold' : '';
    },
    rowClassFn3 (row) {
      return row.Head == 'Total' ? 'font-weight-bold' : '';
    },
  }
};
</script>
