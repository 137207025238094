const currFy = 'fy22';

function toQueryString(params, qs = '') {
  Object.keys(params).forEach(key => {
    qs += qs ? ('&' + key + '=' + params[key]) : ('?' + key + '=' + params[key])
  });
  return qs;
}



function handleError(error) {
  if(error == 0)
    alert(error);
}



function formatNumber(number, d = 0) {
  if(Math.abs(number) < 0.0001)
    return '-';
  number = Math.round(number * Math.pow(10, d));
  let numberStr = Math.abs(number) + '';
  let l = numberStr.length;
  for(; l < d; l++)
    numberStr = '0' + numberStr;
  if(l == d)
    numberStr = '0.' + numberStr;
  else if(l <= 3 + d)
    numberStr = numberStr.substring(0, l - d) + (d ? '.' + numberStr.substring(l - d) : '');
  else if(l <= 5 + d)
    numberStr = numberStr.substring(0, l - 3 - d) + ',' + numberStr.substring(l - 3 - d, l - d) + (d ? '.' + numberStr.substring(l - d) : '')
  else
    numberStr = numberStr.substring(0, l - 5 - d) + ',' + numberStr.substring(l - 5 - d, l - 3 - d) + ',' + numberStr.substring(l - 3 - d, l - d) + (d ? '.' + numberStr.substring(l - d) : '');
  return (number < 0 ? '-' : '') + numberStr;
}

function formatPercentage(amount, d = 0) {
  let f = formatNumber(amount * 100, d);
  return f == '-' ? f : f + '%';
}

function formatCurrency(amount, d = 0) {
  if(!amount || Math.abs(amount) < 0.0001)
    return '-';
  let f = formatNumber(Math.abs(amount), d);
  if(f == '-')
    return '₹0';
  return amount < 0 ? ('- ₹' + f) : ('₹' + f);
}

function formatDate(date) {
  return date
    ? [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ][parseInt(date.substring(5,7)) - 1] + ' ' + date.substring(8, 10)
    : '-'
}

function cssCurrency(amount, min, mid, max) {
  if(amount < min)
    return 'deep-orange darken-4 grey--text text--lighten-4';
  if(amount < mid - (mid - min) / 9 * 8)
    return 'deep-orange darken-3';
  if(amount < mid - (mid - min) / 9 * 7)
    return 'deep-orange darken-2';
  if(amount < mid - (mid - min) / 9 * 6)
    return 'deep-orange darken-1';
  if(amount < mid - (mid - min) / 9 * 5)
    return 'deep-orange';
  if(amount < mid - (mid - min) / 9 * 4)
    return 'deep-orange lighten-1';
  if(amount < mid - (mid - min) / 9 * 3)
    return 'deep-orange lighten-2';
  if(amount < mid - (mid - min) / 9 * 2)
    return 'deep-orange lighten-3';
  if(amount < mid - (mid - min) / 9 * 1)
    return 'deep-orange lighten-4';
  if(amount < mid - 0.00001)
    return 'deep-orange lighten-5';
  if(amount < mid + 0.00001)
    return '';
  if(amount <= mid + (max - mid) / 9 * 1)
    return 'light-green lighten-5';
  if(amount <= mid + (max - mid) / 9 * 2)
    return 'light-green lighten-4';
  if(amount <= mid + (max - mid) / 9 * 3)
    return 'light-green lighten-3';
  if(amount <= mid + (max - mid) / 9 * 4)
    return 'light-green lighten-2';
  if(amount <= mid + (max - mid) / 9 * 5)
    return 'light-green lighten-1';
  if(amount <= mid + (max - mid) / 9 * 6)
    return 'light-green';
  if(amount <= mid + (max - mid) / 9 * 7)
    return 'light-green darken-1';
  if(amount <= mid + (max - mid) / 9 * 8)
    return 'light-green darken-2';
  if(amount <= max)
    return 'light-green darken-3';
  // if(amount > max )
    return 'light-green darken-4 grey--text text--lighten-4';
}

function arrayToVuetifyTableRows(dataArr, headers) {
  let rows = [];
  for(let i = 0; i < dataArr.length; i++) {
    let row = { key: dataArr[i][0] };
    headers.forEach((header, j) => {
      if(header.type == 'number')
        row[header.value] = formatNumber(dataArr[i][j], 0);
      else if(header.type == 'number2d')
        row[header.value] = formatNumber(dataArr[i][j], 2);
      else if(header.type == 'number3d')
        row[header.value] = formatNumber(dataArr[i][j], 3);
      else if(header.type == 'percentage2d')
        row[header.value] = formatPercentage(dataArr[i][j], 2);
      else if(header.type == 'currencyInr')
        row[header.value] = formatCurrency(dataArr[i][j], 0);
      else if(header.type == 'currencyInr2d')
        row[header.value] = formatCurrency(dataArr[i][j], 2);
      else
        row[header.value] = dataArr[i][j];
    });
    rows.push(row);
  }
  return rows;
}



export default { currFy, toQueryString, handleError, formatNumber, formatPercentage, formatCurrency, formatDate, cssCurrency, arrayToVuetifyTableRows };
