<template>
  <v-card flat>
    <v-card-title class="pb-2" v-if="title">
      <span>{{ title }}</span>
      <v-spacer></v-spacer>
      <slot name="title"></slot>
    </v-card-title>
    <v-card-text>
      <v-data-table

        dense
        :headers="headers"
        :items="items"
        no-data-text="no data"
        :item-class="rowClassFn"

        v-model="selected"
        item-key="key"
        :single-select="multiSelect != true"
        @click:row="handleClick"

        disable-sort
        disable-pagination
        :hide-default-header="data.length == 2"
        hide-default-footer

      ></v-data-table>
    </v-card-text>
  </v-card>
</template>



<script>
import axios from "axios";
import util from "@/util.js";
export default {
  props: {
    title: String,
    api: String,
    params: Object,
    currencyFormat: String,
    multiSelect: Boolean,
    rowClassFn: Function,
    rowClickFn: Function
  },
  data() {
    return {
      data: [],
      selected: []
    }
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    params: {
      handler() { this.fetchData() },
      deep: true
    },
    '$route'() {
      this.fetchData();
    }
  },
  computed: {
    headers () {
      if(!this.data.length)
        return [];
      let headers = [];
      this.data[0].forEach((head, i) => {
        if(i == 0)
          headers.push({ text:head, value:head });
        else
          headers.push({ text:head, value:head, align:'end', type:this.currencyFormat || 'currencyInr2d' });
      });
      return headers;
    },
    items () {
      if(!this.data.length || this.data.length == 2)
        return [];
      return util.arrayToVuetifyTableRows(this.data.slice(1), this.headers);
    }
  },
  methods: {
    fetchData () {
      axios
        .get(this.api, { params: this.params })
        .then((response) => {
          this.data = response.data;
        })
        .catch((error) => {
          util.handleError(error);
        });
    },
    handleClick (val) {

      if(this.multiSelect) {
        let i = this.selected.indexOf(val);
        if(i == -1)
          this.selected.push(val)
        else
          this.selected.splice(i, 1);
      } else {
        this.selected = [ val ];
      }

      if(this.rowClickFn)
        this.rowClickFn(this.selected);

    },
  },
};
</script>
